import React, {useEffect, useState} from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import {navigate} from 'gatsby'


export default function WelcomePage({data, location}) {

  return null
}
